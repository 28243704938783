
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import { loadConfig, updateBatchNumber } from "@/components/devices/helpers";

import { loadAttributes } from "tb/attributes";
import * as Sentry from "@sentry/vue";
import { calc_grams, db, Gravity, PitchRate, Volume, Yeast } from "@/types";

const initial = {
  BATCH_NUMBER: null,
  BATCH: null,
  ALICAT: null,
  SCALE: null,
  ZYMOMETER: null,
  OM: null,
  OV: null,
  OG: null,
  OD: null,
  PITCH_RATE: null,
  GRAVITY_LABEL: null,
  YEAST: null,
  MALT_TYPE: null,
  MALT: null,
  MALT_EXTRACT: null,
  YEAST_WEIGHT: null,
  PURGED_WITH_CO2: false,
  INTERNAL_PRESSURE_SENSOR_TYPE: "ABSOLUTE",
  CHECKLIST: [],
  COMMENT: null,
  date: moment().format("YYYY-MM-DD"),
  time: moment().format("HH:mm:ss"),
};

const keys = ["COMMENT"];

@Component({
  components: {},
  async mounted() {
    this.$data.loading = true;
    try {
      await (this as any).load_config();
      await (this as any).load_status(this.$data.alicat);
      await (this as any).load_status(this.$data.zymometer);
    } catch (error) {
      alert("Failed to initialize. Details: " + error);
    }
    this.$data.loading = false;
  },
  computed: {
    calculated_grams() {
      if (
        this.$data.input.OG &&
        this.$data.input.OV &&
        this.$data.input.PITCH_RATE &&
        this.$data.input.YEAST
      ) {
        let pitch: PitchRate = new PitchRate(
          new Gravity(this.$data.input.OG),
          new Volume(this.$data.input.OV),
          this.$data.input.PITCH_RATE,
          (this.$data.input.YEAST as Yeast).parameters.viability
        );
        return calc_grams(pitch);
      }
      return 0;
    },
    liquidToHeadspaceVolumeRatio() {
      let totalVesselVolume = 70;
      return (parseFloat(this.$data.input.OV || "0") / totalVesselVolume) * 100;
    },
  },
  data: () => ({
    rules: {
      REQUIRED: [(value: any) => !!value || "Required."],
    },
    db,
    alicat: [],
    scale: [],
    zymometer: [],
    ex4: [],
    batches: [],
    saving: false,
    update: false,
    delete_ts: null,
    valid: true,
    measurements: [],
    error: null,
    input: initial,
    moment,
    loading: false,
  }),
  methods: {
    async startBatch() {
      try {
        (this as any).error = null;
        (this as any).loading = true;
        let input = this.$data.input;

        await (this as any).$refs.form.validate();

        if (!(this as any).valid) {
          throw Error("Please make sure to fill out all required fields.");
        }

        if (!input.ZYMOMETER) {
          throw Error("Zymometer not selected");
        }
        if (!input.ALICAT) {
          throw Error("Alicat flowmeter not selected");
        }
        if (!input.YEAST) {
          throw Error("Yeast type not selected");
        }
        if (!input.MALT && !input.MALT_EXTRACT) {
          throw Error("Malt type not selected");
        }

        let letime = "";
        if (/^\d{2}:\d{2}:\d{2}$/gi.test(input.time)) {
          letime = input.date + "T" + input.time;
        } else {
          letime = input.date + "T" + input.time + ":00";
        }
        console.warn("Time string: ", letime);
        console.warn(
          "Time string processed: ",
          letime + `.000+0${moment(letime).isDST() ? 2 : 1}:00`
        );
        console.warn("DST:", moment(letime).isDST());
        let ts = +new Date(
          letime + `.000+0${moment(letime).isDST() ? 2 : 1}:00` // forgive me god, copenhell zone!
        );

        let form = { ...this.$data.input };
        let batch = {
          name: form.BATCH,
          number: parseInt(form.BATCH_NUMBER),
          author: {
            email: this.$store.state.user.email,
          },
          createdTime: +new Date(),
          startTime: ts,
          startTimeText: letime,
          endTime: null,
          state: "BREWING",
          devices: {
            ALICAT: form.ALICAT,
            SCALE: form.SCALE,
            ZYMOMETER: form.ZYMOMETER,
            VESSEL: form.VESSEL,
          },
          flags: {
            HEADSPACE_CO2_RECIRCULATION: form.HEADSPACE_CO2_RECIRCULATION,
            PURGED_WITH_CO2: form.PURGED_WITH_CO2,
            PRESSURE_FERMENTATION: parseFloat(form.SET_PRESSURE ?? "0") > 0,
            INTERNAL_PRESSURE_SENSOR_TYPE: form.INTERNAL_PRESSURE_SENSOR_TYPE,
            GRAVITY_LABEL: form.GRAVITY_LABEL,
          },
          pitchRate: {
            value: parseFloat(form.PITCH_RATE),
            unit: "million cells/ml",
          },
          originalMass: { value: parseFloat(form.OM) * 1000, unit: "GRAM" },
          liquidVolume: { value: parseFloat(form.OV), unit: "LITER" },
          originalVolume: { value: parseFloat(form.OV), unit: "LITER" },
          originalGravity: { value: parseFloat(form.OG), unit: "SG" },
          originalDensity: {
            value: parseFloat(form.OD),
            unit: "GRAM/MILLILITER",
          },
          liquidToHeadspaceVolumeRatio: {
            value: (this as any).liquidToHeadspaceVolumeRatio,
            unit: "%",
          },
          yeast: {
            ...form.YEAST,
            type: form.YEAST.name,
            weight: parseFloat((this as any).calculated_grams),
            unit: "GRAM",
          },
          malt: {
            type: form.MALT_TYPE.category,
            name: form.MALT_EXTRACT?.name ?? form.MALT,
            parameters: form.MALT_EXTRACT?.parameters,
            weight: null,
            unit: "GRAM",
          },
          events: [
            {
              type: "START_BATCH",
              ts,
              author: {
                email: this.$store.state.user.email,
              },
              input: form,
            },
          ],
          form,
        };

        let result = await axios.post(
          "https://lab.zymoscope.com/api/start-batch",
          { ...batch },
          this.$store.getters.tb_api_headers
        );

        console.warn("started", result.data);
        console.warn("status", result.status);

        alert(
          "Batch started. Status code should be 200: " +
            result.status +
            "\nBatch should now appear in the backlog."
        );

        this.$data.input.BATCH = null;
        this.$data.input.VESSEL = null;
        this.$data.input.OM = null;
        this.$data.input.OV = null;
        this.$data.input.OG = null;
        this.$data.input.OD = null;
        this.$data.input.YEAST = null;
        this.$data.input.MALT = null;
        this.$data.input.MALT_EXTRACT = null;
        this.$data.input.MALT_TYPE = null;
        this.$data.input.YEAST_WEIGHT = null;
        this.$data.input.PITCH_RATE = null;
        this.$data.input.GRAVITY_LABEL = null;

        this.$store.dispatch("loadBatches");
        (this as any).loading = false;
      } catch (error) {
        (this as any).loading = false;
        (this as any).error = error;
        return error;
      }
    },
    async clearMalt() {
      this.$data.input.MALT_EXTRACT = null;
      this.$data.input.MALT = null;
    },
    async load_status(devices) {
      try {
        for (let device of devices) {
          let attr = await loadAttributes(
            `${device.id}`,
            this.$store.getters.tb_api_headers
          );
          device.status = attr.active ? "online" : "offline";
          console.warn("device", device, attr);
        }
      } catch (error) {
        return error;
      }
    },
    async load_config() {
      try {
        let config = await loadConfig(this.$store.getters.tb_api_headers);
        console.warn("config", config);
        let get = (key: string) => config.fermentors.map((x: any) => x[key]);
        this.$data.config = config;
        this.$data.alicat = get("alicat");
        this.$data.zymometer = get("zymometer");
        this.$data.input.BATCH_NUMBER = config.batch_number;
      } catch (error) {
        (this as any).measurements_error = error;
        return error;
      }
    },
    async increment() {
      try {
        this.$data.input.BATCH_NUMBER = this.$data.input.BATCH_NUMBER + 1;
        await updateBatchNumber(
          this.$data.input.BATCH_NUMBER,
          this.$store.getters.tb_api_headers
        );
        await (this as any).load_config();
      } catch (error) {
        (this as any).measurements_error = error;
        return error;
      }
    },
    async calculate_ov() {
      try {
        if (this.$data.input.OD && this.$data.input.OM) {
          this.$data.input.OV = this.$data.input.OM / this.$data.input.OD;
          this.$data.input.p = this.$data.input.OM / this.$data.input.OD;
        }
      } catch (error) {
        (this as any).measurements_error = error;
        return error;
      }
    },
    async change_batch_number() {
      try {
        console.log("change_batch_number", this.$data.input.BATCH_NUMBER);
        await updateBatchNumber(
          this.$data.input.BATCH_NUMBER,
          this.$store.getters.tb_api_headers
        );
      } catch (error) {
        (this as any).measurements_error = error;
        return error;
      }
    },
    async assign() {
      try {
        this.$data.loading = true;
        let vessel = this.$data.input.VESSEL;
        this.$data.input.ALICAT = this.$data.alicat.find(
          (x: any) => x.id == vessel.alicat.id
        );
        this.$data.input.SCALE = this.$data.scale.find(
          (x: any) => x.id == vessel.scale.id
        );
        this.$data.input.ZYMOMETER = this.$data.zymometer.find(
          (x: any) => x.id == vessel.zymometer.id
        );
        console.log("assign", vessel);
        this.$data.loading = false;
      } catch (error) {
        console.error("FAIL TO ASSIGN", error);
        (this as any).measurements_error = error;
        this.$data.loading = false;
        throw error;
      }
    },
  },
})
export default class StartBatch extends Vue {}
