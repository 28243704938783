export type Range = {
    min: { value: number, unit: string }
    max: { value: number, unit: string }
}

const sg_to_plato = ({ value: input }: OG): number => {
    return (-460.234 + (662.649 * input) - (202.414 * Math.pow(input, 2)));
    // return (135.997 * Math.pow(input, 3) - 630.272 * Math.pow(input, 2) + 1111.14 * input - 616.868);
};


export type CommandType =
    | "delete_yeast"
    | "update_yeast"
    | "add_yeast"
    | "get_yeast"
    | "add_malt"
    | "get_malt"
    | "update_malt"
    | "delete_malt";

export type Command = {
    command: CommandType;
    payload: any;
};

export class Gravity {
    value: number
    constructor(sg: number) {
        let min = 1
        let max = 1.2
        if (sg < min || sg > max)
            throw Error(`Not allowed values for SG ${sg}. Allowed range is ${min} to ${max}`)
        this.value = sg
    }

    plato() {
        return sg_to_plato(this)
    }
    sg() {
        return this.value
    }
}

export class Volume {
    value: number
    constructor(liter: number) {
        let min = 0
        if (liter < min)
            throw Error(`Not allowed values for Volume ${liter}. Minimum liters allowed is ${min}`)
        this.value = liter
    }
    liter() { return this.value }
    ml() {
        return this.value * 1000
    }
}

// export type SG = number
export type OG = Gravity
export type liter = Volume
export type gram = number
export type Cfu_g = number
export type pct = number

export type MinimumManufacturerRecommendation = 0.35
export type ProBrewerMiddleGravityAle = 0.75
export type ProBrewerHighGravityAle = 1.0
export type ProBrewerMinimumLager = 1.5
export type ProBrewerHighGravityLager = 2.0

export type TargePitchRate =
    MinimumManufacturerRecommendation
    | ProBrewerMiddleGravityAle
    | ProBrewerHighGravityAle
    | ProBrewerMinimumLager
    | ProBrewerHighGravityLager

export type MaltExtract = {
    name: string
    url: string
    parameters: {
        extract: Range
        specific_weight: Range
        color: Range
        color_hex: string
    }
}
export interface MaltExtractOption {
    category: 'malt_extract',
    items: MaltExtract[]
}

export type BeerOption = {
    category: 'beer',
    name: string
}

export type MaltOption = MaltExtractOption | BeerOption

export type Yeast = {
    name: string,
    url: string,
    parameters: {
        viability: Cfu_g,
        purity: pct,
        dosage_range: Range,
        temperature_range: Range,
        alcohol_tolerance_range: Range,
        attenuation: Range,
        form: 'dry' | 'liquid'
    }
}

export class PitchRate {
    readonly CellsPrMl = 1000000

    constructor(
        readonly OG: OG,
        readonly OV: liter,
        readonly PitchRate: TargePitchRate,
        readonly CellDensity: Cfu_g,
    ) {
    }

}


export const calc_grams = ({ OG, OV, PitchRate, CellDensity, CellsPrMl }: PitchRate): gram => {
    let cells_pr_ml = PitchRate * CellsPrMl
    // console.debug('cells_pr_ml', cells_pr_ml)
    // console.debug(OG, OG.plato())
    // console.debug(OV, OV.ml())
    // console.debug(CellDensity)
    return (OG.plato() * OV.ml() * cells_pr_ml) / CellDensity
}

export const calc_pitch_rate = (grams: gram, { OG, OV, CellDensity, CellsPrMl }: PitchRate): number => {
    return (CellDensity * grams) / (OG.plato() * OV.ml() * CellsPrMl)
}


interface Array<T> {
    // ...

    // Type declaration overload.
    find<S extends T>(
        predicate: (this: void, value: T, index: number, obj: T[]) => value is S,
        thisArg?: any
    ): S | undefined;
    // Type declaration from above.
    find(
        predicate: (value: T, index: number, obj: T[]) => unknown,
        thisArg?: any
    ): T | undefined;
}

export const db: { yeast: Yeast[], malt: Array<MaltOption>, fermentors: any[] } = {
    fermentors: [
        {
            "name": "Fermentor 5",
            "id": "ASSET/44be1740-c77d-11eb-ae0e-1f8899a6f9b3",
            "alicat": {
                "name": "INHOUSE_5",
                "id": "DEVICE/3315ec00-f221-11ec-8399-0d13b9a7c3cc"
            },
            "zymometer": {
                "name": "FS36210_007_5",
                "id": "DEVICE/b89b0490-fdf3-11ec-8d24-abe8f9695e8e"
            }
        },
        {
            "name": "Fermentor 6",
            "id": "ASSET/10f76420-c77d-11eb-8c1b-1520a2e6ced5",
            "alicat": {
                "name": "INHOUSE_6",
                "id": "DEVICE/39a91e70-f221-11ec-8399-0d13b9a7c3cc"
            },
            "zymometer": {
                "name": "FS36210_008_6",
                "id": "DEVICE/a4d99320-fdf5-11ec-ad04-83d2f1b7c972"
            }
        },
        {
            "name": "Ferminator 9000 (testing)",
            "id": "ASSET/f26f29b0-ddc5-11ec-aa04-3996adefc6d5",
            "alicat": {
                "name": "Randometer_1",
                "id": "DEVICE/fea84cf0-af4c-11eb-9e97-1520a2e6ced5"
            },
            "zymometer": {
                "name": "Randometer_2",
                "id": "DEVICE/04999a10-af4d-11eb-9e97-1520a2e6ced5"
            }
        }
    ],
    malt: [
        {
            category: "malt_extract",
            items: [
                {
                    name: 'Weyermann® Bavarian Pilsner',
                    url: 'https://www.weyermann.de/en-us/product/weyermann-bavarian-pilsner-4/',
                    parameters: {
                        extract: {
                            min: {
                                value: 72,
                                unit: 'GG%/BRIX°'
                            },
                            max: {
                                value: 79,
                                unit: 'GG%/BRIX°'
                            }
                        },
                        specific_weight: {
                            min: {
                                value: 1.35,
                                unit: 'kg/l'
                            },
                            max: {
                                value: 1.4,
                                unit: 'kg/l'
                            }
                        },
                        color: {
                            min: {
                                value: 10,
                                unit: 'EBC'
                            },
                            max: {
                                value: 20,
                                unit: 'EBC'
                            }
                        },
                        color_hex: srmToRGB(srmFromEbc((10 + 20) / 2))
                    }
                },
                {
                    name: 'Weyermann® Bavarian Hefeweizen',
                    url: 'https://www.weyermann.de/en-us/product/weyermann-bavarian-hefeweizen-7/',
                    parameters: {
                        extract: {
                            min: {
                                value: 72,
                                unit: 'GG%/BRIX°'
                            },
                            max: {
                                value: 79,
                                unit: 'GG%/BRIX°'
                            }
                        },
                        specific_weight: {
                            min: {
                                value: 1.35,
                                unit: 'kg/l'
                            },
                            max: {
                                value: 1.4,
                                unit: 'kg/l'
                            }
                        },
                        color: {
                            min: {
                                value: 20,
                                unit: 'EBC'
                            },
                            max: {
                                value: 35,
                                unit: 'EBC'
                            }
                        },
                        color_hex: srmToRGB(srmFromEbc((20 + 35) / 2))
                    }
                }
            ]
        },
        {
            category: "beer",
            name: ''
        }
    ],
    yeast: [
        {
            name: "SafAle™ US-05",
            url: "https://fermentis.com/en/product/safale-us-05/",
            parameters: {
                viability: 1 * Math.pow(10, 10),
                purity: 99.9,
                alcohol_tolerance_range: {
                    min: {
                        value: 9,
                        unit: "%",
                    },
                    max: {
                        value: 11,
                        unit: "%",
                    },
                },
                dosage_range: {
                    min: {
                        value: 50,
                        unit: "g/hl",
                    },
                    max: {
                        value: 80,
                        unit: "g/hl",
                    },
                },
                temperature_range: {
                    min: {
                        value: 18,
                        unit: "°C",
                    },
                    max: {
                        value: 26,
                        unit: "°C",
                    },
                },
                attenuation: {
                    min: {
                        value: 72,
                        unit: "%",
                    },
                    max: {
                        value: 82,
                        unit: "%",
                    },
                },
                form: "dry",
            },
        },
        {
            name: "SafAle™ S-04",
            url: "https://fermentis.com/en/product/safale-us-05/",
            parameters: {
                viability: 1 * Math.pow(10, 10),
                purity: 99.9,
                alcohol_tolerance_range: {
                    min: {
                        value: 9,
                        unit: "%",
                    },
                    max: {
                        value: 11,
                        unit: "%",
                    },
                },
                dosage_range: {
                    min: {
                        value: 50,
                        unit: "g/hl",
                    },
                    max: {
                        value: 80,
                        unit: "g/hl",
                    },
                },
                temperature_range: {
                    min: {
                        value: 18,
                        unit: "°C",
                    },
                    max: {
                        value: 26,
                        unit: "°C",
                    },
                },
                attenuation: {
                    min: {
                        value: 74,
                        unit: "%",
                    },
                    max: {
                        value: 82,
                        unit: "%",
                    },
                },
                form: "dry",
            },
        },
        {
            name: "SafLager™ W-34/70",
            url: "https://fermentis.com/en/product/saflager-w-34-70/",
            parameters: {
                viability: 6 * Math.pow(10, 9),
                purity: 99.9,
                alcohol_tolerance_range: {
                    min: {
                        value: 9,
                        unit: "%",
                    },
                    max: {
                        value: 11,
                        unit: "%",
                    },
                },
                dosage_range: {
                    min: {
                        value: 80,
                        unit: "g/hl",
                    },
                    max: {
                        value: 120,
                        unit: "g/hl",
                    },
                },
                temperature_range: {
                    min: {
                        value: 12,
                        unit: "°C",
                    },
                    max: {
                        value: 18,
                        unit: "°C",
                    },
                },
                attenuation: {
                    min: {
                        value: 80,
                        unit: "%",
                    },
                    max: {
                        value: 84,
                        unit: "%",
                    },
                },
                form: "dry",
            },
        },
    ]
}


function srmFromEbc(ebc: number) {
    return ebc / 1.97;
}


function srmToRGB(srm: number) {
    // Returns an RGB value based on SRM
    let r = 0, g = 0, b = 0;

    if (srm >= 0 && srm <= 1) {
        r = 240;
        g = 239;
        b = 181;
    } else if (srm > 1 && srm <= 2) {
        r = 233;
        g = 215;
        b = 108;
    } else if (srm > 2) {
        // Set red decimal
        if (srm < 70.6843) {
            r = 243.8327 - 6.4040 * srm + 0.0453 * srm * srm;
        } else {
            r = 17.5014;
        }
        // Set green decimal
        if (srm < 35.0674) {
            g = 230.929 - 12.484 * srm + 0.178 * srm * srm;
        } else {
            g = 12.0382;
        }
        // Set blue decimal
        if (srm < 4) {
            b = -54 * srm + 216;
        } else if (srm >= 4 && srm < 7) {
            b = 0;
        } else if (srm >= 7 && srm < 9) {
            b = 13 * srm - 91;
        } else if (srm >= 9 && srm < 13) {
            b = 2 * srm + 8;
        } else if (srm >= 13 && srm < 17) {
            b = -1.5 * srm + 53.5;
        } else if (srm >= 17 && srm < 22) {
            b = 0.6 * srm + 17.8;
        } else if (srm >= 22 && srm < 27) {
            b = -2.2 * srm + 79.4;
        } else if (srm >= 27 && srm < 34) {
            b = -0.4285 * srm + 31.5714;
        } else {
            b = 17;
        }
    }
    let red = doubleToHex(r);
    let green = doubleToHex(g);
    let blue = doubleToHex(b);
    return "" + red + green + blue;
}

function doubleToHex(d: number) {
    // Converts decimal in string to hex in string 
    let hexText = d.toString(16);
    let point = hexText.indexOf(".");
    if (point != -1) {
        hexText = hexText.substring(0, point);
    }
    while (hexText.length < 2) {
        hexText = "0" + hexText;
    }
    return hexText;
}
